import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import {
  Validators,
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl,
  FormArray,
} from "@angular/forms";
import { MatLegacyCheckboxModule as MatCheckboxModule } from "@angular/material/legacy-checkbox";
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { APP_DATE_FORMATS } from "../../../format-datepicker";
import * as moment from "moment";
import { environment } from "../../../../environments/environment";
import { Router } from "@angular/router";
import { RegisterPageService } from "../../../services/register-page.service";
import { DataService } from "../../../services/data.service";
import { PopupTypes } from "../../../models/popuptype";
import { DateFormat } from "../../../services/date-format.service";
import { TextService } from "../../../services/text.service";
import { ModalService } from "src/app/services/modal.service";
import {
  IFieldOptions,
  getPensionPolicyDate,
  IEmployeeData,
} from "src/app/models/register-user-data.model";
import { ISelectOption } from "src/app/models/import-new-employees.model";
import { GeneralPopupComponent } from "../../common/general-popup/general-popup.component";
import { MatLegacyButtonModule } from "@angular/material/legacy-button";
import { RadioButtonComponent } from "../../common/radio-button/radio-button.component";
import { SelectComponent } from "../../common/select/select.component";
import { MatLegacyOptionModule } from "@angular/material/legacy-core";
import { MatLegacySelectModule } from "@angular/material/legacy-select";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatLegacyInputModule } from "@angular/material/legacy-input";
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field";
import { MatLegacyRadioModule } from "@angular/material/legacy-radio";
import { NgIf, NgClass, NgFor, CommonModule } from "@angular/common";
import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

declare var $;
@Component({
  selector: "app-register-stage-component",
  templateUrl: "./register-stage-component.component.html",
  styleUrls: ["./register-stage-component.component.scss"],
  providers: [
    { provide: DateAdapter, useClass: DateFormat },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatLegacyRadioModule,
    MatLegacyFormFieldModule,
    MatLegacyInputModule,
    MatDatepickerModule,
    MatLegacySelectModule,
    MatLegacyOptionModule,
    SelectComponent,
    RadioButtonComponent,
    MatLegacyButtonModule,
    GeneralPopupComponent,
  ],
})
export class RegisterStageComponentComponent implements OnInit {
  @Input() stageNumber;
  @Input() stageTitle;
  @Output() nextStep = new EventEmitter();
  @Output() backStep = new EventEmitter();
  @Input() name_img;
  public showDialog = false;
  public closeMonthlyOrHourlyPoup = false;

  monthlyOrHourly = PopupTypes.monthlyOrHourly;
  NumbersOrDecimalsRegex: RegExp = /^\d*\.?\d+$/i;
  radioOptions: any;
  today: Date;
  trySubmit = false;
  disableContinueButton = false;
  name = environment.name;
  userDetailsFormStepOne: UntypedFormGroup;
  userDetailsFormStepTwo: UntypedFormGroup;
  userDetailsFormStepThree: UntypedFormGroup;
  genders = ["זכר", "נקבה", "אחר"];
  validation_messages: any;
  err_message = "";
  canYouContinueToStepTwo;
  error = PopupTypes.error;

  profileObject = {
    //stage1
    foreign_employee: false,
    first_name: "",
    last_name: "",
    gender: "",
    idRadio: true,
    id: "",
    birthdate: "",
    family_status: {},
    salary_method: {},
    mobile_phone: "",
    email: "",
    language: "",
    city: "",
    street: "",
    bulding_number: "",
    //stage2
    role: "",
    employeeNumber: "",
    startDate: "",
    employeeSalary: "",
    insuredSalaryEmployee: "",
    //stage3
    employer_compensation: "",
    employee_compensation: "",
    allowance_compensation: "",
    allowance_compensation_other: "",
    section_14: "",
    pension_arrangement: "",
    date_pension_arrangement: "",
    health_collective: "",
    loss_work_ability: "",
    employer_compensation_loss_work: "",
    employee_compensation_loss_work: "",
    eligibility_study_fund: "",
    is_up_to_the_ceiling_of_the_training_fund: "",
    input_for_other_study_fund_deposit: "",
    input_for_percentage_study_fund_deposit: "",
    employer_retirement: "",
    employee_retirement: "",
    date_training_fund: "",
    eligibility_date_training_fund: "",
    display_study_fund: "",
    display_aka_section: "",
    eligible_health_insurance: false,
    display_moed_zakaut_drop_down: "",
    moed_zakaut_default_value: { label: "", value: "" },
  };
  familystatuses = ["רווקה", "נשואה", "אלמנה", "פרודה"];
  language_options = [];
  salary_options = [
    {
      label: "שעתי",
      value: "hourly",
    },
    {
      label: "חודשי",
      value: "monthly",
    },
  ];
  allowance_compensations_percentage = ["0.4", "1.6", "2.1"];
  eligibility_date_study_fund = [];
  genderTranslate = {
    Female: "נקבה",
    Male: "זכר",
    female: "נקבה",
    male: "זכר",
  };
  genderTranslateForUpdate = {
    נקבה: "Female",
    זכר: "Male",
  };
  employee_benefits_incapacity_to_work;
  employer_benefits_incapacity_to_work;
  eligibility_date;
  titleName;
  deposit_for_compensation;
  study_fund_employee_deposit;
  study_fund_employer_deposit;
  explainDate =
    ",תאריך לידה, ניתן להזין ידנית את התאריך בפורמט הבא: dd-mm-yyyy או עבור לשדה תאריך בעזרת טאב לחץ אנטר והזן תאריך בעזרת כפתור הטאב תבחר תחילה שנה, לאחר מכן חודש ולבסוף יום";
  open: boolean = false;
  selected: string = "";
  valid: boolean = false;
  touched: boolean = false;
  button_2_name;
  button_1_name;
  study_fund_deposit;

  warning: number = 0;
  manual_allowance_compensation_field = false;
  date_training_fund_chosen_other = false;
  study_fund_date_of_entitlement = "";
  showInputDeposit = false;
  showInputPercentageDeposit = false;
  foreignEmployeeBulletOptions: ISelectOption[] = [];
  pension_arrangement_chosen_other: boolean = true;
  // pension_arrangement_chosen_other = this.profileObject.pension_arrangement === '' ? true : false;

  constructor(
    public fb: UntypedFormBuilder,
    private router: Router,
    public textService: TextService,
    public dataService: DataService,
    private registerPageService: RegisterPageService
  ) {
    this.today = new Date();
    this.textService.setTextSource();
  }

  ngOnInit(): void {
    // this.userDetailsFormStepTwo.get('employeeSalary').valueChanges.subscribe(() => {
    //   this.userDetailsFormStepTwo.updateValueAndValidity();
    // });

    // this.userDetailsFormStepTwo.get('salary_method').valueChanges.subscribe(() => {
    //   this.userDetailsFormStepTwo.updateValueAndValidity();
    // });
    this.button_2_name =
      this.textService.textSource["NEW_EMPLOYER_STEP_1_BUTTON_2"];
    this.button_1_name =
      this.textService.textSource["NEW_EMPLOYER_STEP_1_BUTTON_1"];
    this.disableContinueButton = false;
    this.validation_messages = this.registerPageService.getValidationMessages();

    this.userDetailsFormStepOne = this.fb.group({
      foreign_employee: new UntypedFormControl(
        this.profileObject.foreign_employee,
        []
      ),
      firstname: new UntypedFormControl(this.profileObject.first_name, [
        Validators.compose([
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(40),
          Validators.pattern(/^[a-z \u0590-\u05fe \']+$/i),
        ]),
      ]),
      lastname: new UntypedFormControl(this.profileObject.last_name, [
        Validators.compose([
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(40),
          Validators.pattern(/^[a-z \u0590-\u05fe \']+$/i),
        ]),
      ]),
      idRadio: new UntypedFormControl(this.profileObject.idRadio, []),
      gender: new UntypedFormControl(this.profileObject.gender, [
        Validators.required,
      ]),
      id: new UntypedFormControl(this.profileObject.id, [
        Validators.compose([Validators.required, this.validateIsraelId]),
      ]),
      birthday: new UntypedFormControl(this.profileObject.birthdate, [
        Validators.compose([Validators.required, this.isMyDateFormat]),
      ]),
      familystatus: new UntypedFormControl(
        this.profileObject.family_status,
        []
      ),
      phone: new UntypedFormControl(this.profileObject.mobile_phone, [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.compose([
          Validators.required,
          Validators.pattern(/^[0-9]+$/i),
        ]),
      ]),
      language: new UntypedFormControl(this.profileObject.language, []),
      email: new UntypedFormControl(this.profileObject.email, [
        Validators.compose([
          Validators.pattern(
            /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i
          ),
        ]),
      ]),
      city: new UntypedFormControl(this.profileObject.city, [
        Validators.maxLength(40),
        Validators.minLength(2),
      ]),
      street: new UntypedFormControl(this.profileObject.street, [
        Validators.maxLength(40),
        Validators.minLength(2),
      ]),
      number: new UntypedFormControl(this.profileObject.bulding_number, [
        Validators.maxLength(8),
      ]),
    });
    if (this.name == "gur") {
      this.userDetailsFormStepOne.controls["email"].setValidators([
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i),
      ]);
    }

    this.userDetailsFormStepTwo = this.fb.group({
      role: new UntypedFormControl(this.profileObject.role, [
        Validators.compose([Validators.minLength(2), Validators.maxLength(40)]),
      ]),
      employeeNumber: new UntypedFormControl(
        this.profileObject.employeeNumber,
        [
          Validators.maxLength(40),
          Validators.minLength(2),
          Validators.pattern(/^[0-9]+$/i),
        ]
      ),
      startDate: new UntypedFormControl("", [Validators.required]),
      salary_method: new UntypedFormControl(this.salary_options[1], [
        ,
        Validators.required,
      ]),
      employeeSalary: new UntypedFormControl(
        this.profileObject.employeeSalary,
        [
          Validators.required,
          Validators.compose([
            Validators.maxLength(6),
            Validators.pattern(/^[0-9]+$/i),
          ]),
        ]
      ),
      insuredSalaryEmployee: new UntypedFormControl(
        this.profileObject.insuredSalaryEmployee,
        [
          Validators.compose([
            Validators.maxLength(6),
            Validators.pattern(/^[0-9]+$/i),
          ]),
        ]
      ),
    });

    this.userDetailsFormStepTwo
      .get("employeeSalary")
      .valueChanges.subscribe(() => {
        this.salaryOptionValidator();
      });
    this.userDetailsFormStepTwo
      .get("salary_method")
      .valueChanges.subscribe(() => {
        this.salaryOptionValidator();
      });

    this.userDetailsFormStepThree = this.fb.group({
      employer_compensation: new UntypedFormControl(
        this.profileObject.employer_compensation,
        []
      ),
      employee_compensation: new UntypedFormControl(
        this.profileObject.employee_compensation,
        []
      ),
      allowance_compensation: new UntypedFormControl(
        this.profileObject.allowance_compensation,
        [, Validators.compose([Validators.required])]
      ),
      health_collective: new UntypedFormControl(
        this.profileObject.health_collective,
        Validators.required
      ),
      section_14: new UntypedFormControl(this.profileObject.section_14, []),
      pension_arrangement: new UntypedFormControl(
        this.profileObject.pension_arrangement,
        [Validators.required]
      ),
      date_pension_arrangement: new UntypedFormControl(
        this.profileObject.date_pension_arrangement
      ),
      employer_compensation_loss_work: new UntypedFormControl(
        this.profileObject.employer_compensation_loss_work,
        []
      ),
      employee_compensation_loss_work: new UntypedFormControl(
        this.profileObject.employee_compensation_loss_work,
        []
      ),
      eligibility_study_fund: new UntypedFormControl(false, []),
      eligible_health_insurance: new UntypedFormControl(false, []),
      is_up_to_the_ceiling_of_the_training_fund: new UntypedFormControl(
        this.profileObject.is_up_to_the_ceiling_of_the_training_fund,
        []
      ),
      input_for_other_study_fund_deposit: new UntypedFormControl("", []),
      input_for_percentage_study_fund_deposit: new UntypedFormControl("", []),
      employer_retirement: new UntypedFormControl(
        this.profileObject.employer_retirement,
        [
          Validators.compose([
            Validators.max(10),
            Validators.min(0),
            Validators.compose([
              Validators.required,
              Validators.pattern("^[0-9]\\d*(\\.\\d{0,10})?$"),
            ]),
          ]),
        ]
      ),
      employee_retirement: new UntypedFormControl(
        this.profileObject.employee_retirement,
        [
          Validators.compose([
            Validators.max(10),
            Validators.min(0),
            Validators.compose([
              Validators.required,
              Validators.pattern("^[0-9]\\d*(\\.\\d{0,10})?$"),
            ]),
          ]),
        ]
      ),
      allowance_compensation_other: new UntypedFormControl(
        this.profileObject.employee_retirement,
        []
      ),
      date_training_fund: new UntypedFormControl(
        this.profileObject.date_training_fund,
        []
      ),
      eligibility_date_training_fund: new UntypedFormControl(
        this.profileObject.eligibility_date_training_fund,
        []
      ),
    });
    this.dataService.showGhostElements = true;
    if (this.dataService.isItMobileDisplay()) {
      this.dataService.showDataLoader = true;
    }
    this.registerPageService.getFormOptions().subscribe(
      (resp) => {
        this.dataService.showDataLoader = false;
        this.dataService.showGhostElements = false;
        this.familystatuses = resp["family_status"];
        this.eligibility_date_study_fund = resp["eligibility_date_study_fund"];
        this.allowance_compensations_percentage =
          resp["allowance_compensations_percentage"];
        this.profileObject.employer_retirement = resp["employer_benefits"];
        this.profileObject.employee_retirement = resp["employee_benefits"];
        this.eligibility_date = resp["eligibility_date"];
        this.deposit_for_compensation = resp["deposit_for_compensation"];
        this.study_fund_employee_deposit = resp["study_fund_employee_deposit"];
        this.study_fund_employer_deposit = resp["study_fund_employer_deposit"];
        this.profileObject.section_14 = resp["paragraph_14"];
        this.profileObject.display_study_fund = resp["display_study_fund"];
        this.profileObject.display_aka_section = resp["display_aka_section"];
        this.language_options = resp["language"];
        this.profileObject.display_moed_zakaut_drop_down =
          resp["display_moed_zakaut_drop_down"];
        this.profileObject.moed_zakaut_default_value = Array.isArray(
          resp["moed_zakaut_default_value"]
        )
          ? ""
          : typeof resp["moed_zakaut_default_value"] === "object"
          ? resp["eligibility_date"].find(
              (eligibility_date) =>
                eligibility_date.label ===
                resp["moed_zakaut_default_value"].label
            )
          : "";
        this.employer_benefits_incapacity_to_work =
          resp["employer_benefits_incapacity_to_work"];
        this.employee_benefits_incapacity_to_work =
          resp["employee_benefits_incapacity_to_work"];
        this.study_fund_deposit = resp["study_fund_deposit"];
        this.study_fund_date_of_entitlement =
          resp["study_fund_date_of_entitlement"];
        this.userDetailsFormStepThree.patchValue({
          section_14: resp["paragraph_14"],
          employer_retirement: resp["employer_benefits"],
          employee_retirement: resp["employee_benefits"],
          // eligibility_study_fund : resp['display_study_fund'],
          health_collective: resp["display_aka_section"],
          eligible_health_insurance: !!resp["health_collective_default_value"],
          pension_arrangement: this.profileObject.moed_zakaut_default_value,
          employer_compensation_loss_work:
            resp["employer_benefits_incapacity_to_work"],
          employee_compensation_loss_work:
            resp["employee_benefits_incapacity_to_work"],
        });
        this.pension_arrangement_chosen_other =
          this.userDetailsFormStepThree.value.pension_arrangement["label"] ===
          "אחר"
            ? true
            : false;
        this.pension_arrangement_chosen_other =
          this.userDetailsFormStepThree.value.pension_arrangement["label"] ===
          "אחר"
            ? true
            : false;
        this.setFieldDefaultValue(
          this.userDetailsFormStepOne,
          "language",
          this.language_options
        );

        // set the Default Dropdown value
        let defaultObj = this.deposit_for_compensation.find((item) => {
          return item.default_value === true;
        });
        if (defaultObj)
          this.userDetailsFormStepThree.controls[
            "allowance_compensation"
          ].setValue(defaultObj);
        setTimeout(() => {
          $(".img").focus();
        });
      },
      (err) => {
        this.dataService.handleErrors(err);
        window.location.reload();
      }
    );

    this.foreignEmployeeBulletOptions = [
      { label: this.textService.textSource.EMPLOYER_FOREIGN, value: true },
      { label: this.textService.textSource.EMPLOYER_ISRAELI, value: false },
    ];

    this.onChanges();
    this.chooseIdRadio();
    this.userDetailsFormStepOne.markAllAsTouched();
    this.userDetailsFormStepTwo.markAllAsTouched();
    this.userDetailsFormStepThree.markAllAsTouched();

    this.userDetailsFormStepThree.controls.pension_arrangement.valueChanges.subscribe(
      (x) => this.showExtraFormField(x, "date_pension_arrangement")
    );
     this.userDetailsFormStepThree.controls.date_training_fund.valueChanges.subscribe(
      (x) => this.showExtraFormField(x, "eligibility_date_training_fund")      
    );      

  }

  onSubmitUserDetails(valid, value) {}

  choosePassport() {
    this.userDetailsFormStepOne.patchValue({
      idRadio: false,
    });
    this.deleteIdValidation();
    this.userDetailsFormStepOne.controls["idRadio"].disable();
  }
  chooseIdRadio() {
    this.userDetailsFormStepOne.patchValue({
      idRadio: true,
    });
    this.addIdValidation();
    this.userDetailsFormStepOne.controls["idRadio"].enable();
  }

  updateData() {
    if (this.stageNumber == 1 && this.userDetailsFormStepOne.valid) {
      this.disableContinueButton = true;
      if (this.userDetailsFormStepOne.value.birthday != "") {
        let momentDate = moment(this.userDetailsFormStepOne.value.birthday);
        this.userDetailsFormStepOne.value.birthday = momentDate
          .format("YYYY-MM-DD")
          .toString();
      } else {
        this.userDetailsFormStepOne.value.birthday = null;
      }
      this.profileObject.foreign_employee =
        this.userDetailsFormStepOne.value.foreign_employee;
      this.profileObject.first_name =
        this.userDetailsFormStepOne.value.firstname;
      this.profileObject.last_name = this.userDetailsFormStepOne.value.lastname;
      this.profileObject.gender =
        this.genderTranslateForUpdate[this.userDetailsFormStepOne.value.gender];
      this.profileObject.language = this.userDetailsFormStepOne.value.language;
      this.profileObject.id = this.userDetailsFormStepOne.value.id;
      this.profileObject.idRadio = this.userDetailsFormStepOne.value.idRadio;
      this.profileObject.birthdate = this.userDetailsFormStepOne.value.birthday;
      this.profileObject.family_status =
        this.userDetailsFormStepOne.value.familystatus;
      this.profileObject.mobile_phone = this.userDetailsFormStepOne.value.phone;
      this.profileObject.email = this.userDetailsFormStepOne.value.email;
      this.profileObject.city = this.userDetailsFormStepOne.value.city;
      this.profileObject.street = this.userDetailsFormStepOne.value.street;
      this.profileObject.bulding_number =
        this.userDetailsFormStepOne.value.number;

      this.checkValidationStepOne();
    }

    if (
      this.stageNumber == 2 &&
      this.userDetailsFormStepTwo.valid &&
      this.userDetailsFormStepThree.valid
    ) {
      this.disableContinueButton = true;
      if (this.userDetailsFormStepTwo.value.startDate != "") {
        let momentDate = moment(this.userDetailsFormStepTwo.value.startDate);
        this.userDetailsFormStepTwo.value.startDate = momentDate
          .format("YYYY-MM-DD")
          .toString();
      } else {
        this.userDetailsFormStepTwo.value.startDate = null;
      }

      this.profileObject.role = this.userDetailsFormStepTwo.value.role;
      this.profileObject.employeeNumber =
        this.userDetailsFormStepTwo.value.employeeNumber;
      this.profileObject.startDate =
        this.userDetailsFormStepTwo.value.startDate;
      this.profileObject.salary_method =
        this.userDetailsFormStepTwo.value.salary_method;
      this.profileObject.employeeSalary =
        this.userDetailsFormStepTwo.value.employeeSalary;
      this.profileObject.insuredSalaryEmployee =
        this.userDetailsFormStepTwo.value.insuredSalaryEmployee;

      // previously stage 3
      this.profileObject.employer_compensation =
        this.userDetailsFormStepThree.value.employer_compensation["value"];
      this.profileObject.employee_compensation =
        this.userDetailsFormStepThree.value.employee_compensation["value"];
      this.profileObject.allowance_compensation =
        this.userDetailsFormStepThree.value.allowance_compensation;
      this.profileObject.allowance_compensation_other =
        this.userDetailsFormStepThree.value.allowance_compensation_other;
      this.profileObject.section_14 =
        this.userDetailsFormStepThree.value.section_14;
      if (
        this.userDetailsFormStepThree.value.pension_arrangement["label"] ===
        "אחר"
      ) {
        this.profileObject.pension_arrangement = moment(
          this.userDetailsFormStepThree.value.date_pension_arrangement
        )
          .format("YYYY-MM-DD")
          .toString();
      } else {
        this.profileObject.pension_arrangement = getPensionPolicyDate(
          this.profileObject.startDate,
          this.userDetailsFormStepThree.value.pension_arrangement["sfValue"]
        );
      }

      this.profileObject.eligible_health_insurance =
        this.userDetailsFormStepThree.value.eligible_health_insurance;
      this.profileObject.health_collective =
        this.userDetailsFormStepThree.value.health_collective;
      this.profileObject.employer_compensation_loss_work =
        this.userDetailsFormStepThree.value.employer_compensation_loss_work;
      this.profileObject.employee_compensation_loss_work =
        this.userDetailsFormStepThree.value.employee_compensation_loss_work;
      this.profileObject.eligibility_study_fund =
        this.userDetailsFormStepThree.value.eligibility_study_fund;
      this.profileObject.is_up_to_the_ceiling_of_the_training_fund =
        this.userDetailsFormStepThree.value.is_up_to_the_ceiling_of_the_training_fund[
          "value"
        ];
      this.profileObject.employer_retirement =
        this.userDetailsFormStepThree.value.employer_retirement;
      this.profileObject.employee_retirement =
        this.userDetailsFormStepThree.value.employee_retirement;
      if (this.userDetailsFormStepThree.value.eligibility_study_fund) {
        if (
          this.userDetailsFormStepThree.value.date_training_fund["label"] ===
          "אחר"
        ) {
          this.profileObject.date_training_fund = moment(
            this.userDetailsFormStepThree.value.eligibility_date_training_fund
          )
            .format("YYYY-MM-DD")
            .toString();
        } else {
          this.profileObject.date_training_fund = getPensionPolicyDate(
            this.profileObject.startDate,
            this.userDetailsFormStepThree.value.date_training_fund["sfValue"]
          );
        }
      } else {
        this.profileObject.date_training_fund = "";
      }

      this.sendProfileObject();
    }
  }

  sendProfileObject() {
    let body: IEmployeeData = {
      personal_details: {
        first_name: this.profileObject.first_name,
        last_name: this.profileObject.last_name,
        id_number: this.profileObject.id,
        id_number_type: this.profileObject.idRadio ? "תעודת זהות" : "דרכון",
        mobile_phone: this.profileObject.mobile_phone,
        email: this.profileObject.email,
        gender: this.profileObject.gender,
        family_status: this.profileObject.family_status["value"],
        foreign_employee: this.profileObject.foreign_employee,
        language: this.profileObject.language["value"],
        street: this.profileObject.street,
        city: this.profileObject.city,
        bulding_number: this.profileObject.bulding_number,
        date_of_birth: this.profileObject.birthdate,
      },
      work_details: {
        role: this.profileObject.role,
        employer_number: Number(this.profileObject.employeeNumber),
        start_work_date: this.profileObject.startDate,
        monthly_pension_salary: this.profileObject.insuredSalaryEmployee,
      },
      other: {
        employee_incapacity_to_work:
          this.profileObject.employee_compensation_loss_work,
        employer_incapacity_to_work:
          this.profileObject.employer_compensation_loss_work,
        health_collective: this.profileObject.health_collective,
        eligible_health_insurance: this.profileObject.eligible_health_insurance,
      },
      social_allowances: {
        eligibility_date: this.profileObject.pension_arrangement,
        employer_benefits: this.profileObject.employer_retirement,
        employee_benefits: this.profileObject.employee_retirement,
        allowance_compensations_percentage:
          this.profileObject.allowance_compensation["value"],
        paragraph_14: this.profileObject.section_14,
        pension_date_of_entitlement:
          this.userDetailsFormStepThree.value.pension_arrangement["sfValue"] ||
          null,
      },
      fund_study: {
        eligibility_date_study_fund:
          this.profileObject.date_training_fund == ""
            ? null
            : this.profileObject.date_training_fund,
        study_fund_employer_deposit:
          this.profileObject.employer_compensation == ""
            ? null
            : this.profileObject.employer_compensation,
        study_fund_employee_deposit:
          this.profileObject.employee_compensation == ""
            ? null
            : this.profileObject.employee_compensation,
        study_fund_deposit:
          this.profileObject.is_up_to_the_ceiling_of_the_training_fund == ""
            ? null
            : this.profileObject.is_up_to_the_ceiling_of_the_training_fund,
        study_fund_date_of_entitlement:
          this.userDetailsFormStepThree.value.date_training_fund["sfValue"] ||
          null,
        eligibility_study_fund:
          this.userDetailsFormStepThree.value.eligibility_study_fund ?? false,
      },
    };

    //modify relevant body fields before making the request
    this.updateEmployeeDataFields(body);

    const paymentMethod =
      this.profileObject.salary_method["value"] || "monthly";

    const APIPaymentMethod =
      paymentMethod === "monthly" ? "salary" : "hourly_payment";
    body["work_details"][APIPaymentMethod] = this.profileObject.employeeSalary;

    this.dataService.showDataLoader = true;

    this.registerPageService.createEmployee(body).subscribe(
      (resp) => {
        this.dataService.showDataLoader = false;
        this.dataService.showGhostElements = false;

        this.registerPageService.SR_request = resp["service_request"]["name"];
        this.registerPageService.name_request = resp["name"];

        this.router.navigate(["/register-thank-you"]);
      },
      (err) => {
        this.disableContinueButton = false;
        this.dataService.showErrorPopup = true;

        setTimeout(() => {
          $(".close").focus();
        }, 100);
        this.dataService.error_message_popup =
          this.textService.textSource["ERROR_GENERAL"];
        if (this.dataService.name == "gur") {
          this.dataService.error_message_popup =
            this.dataService.error_message_popup +
            " " +
            this.textService.textSource["GUR_PHONE_NUMBER"];
        } else {
          this.dataService.error_message_popup =
            this.dataService.error_message_popup +
            " " +
            this.textService.textSource["NEEMANIM_PHONE_NUMBER"];
        }
        this.dataService.handleErrors(err);
      }
    );
  }

  stepBack() {
    if (this.stageNumber == 1) {
      this.router.navigateByUrl("/");
    } else {
      this.backStep.emit();
      this.disableContinueButton = false;
      this.insertButtonsName(this.stageNumber - 1);
    }
    setTimeout(() => {
      $(".img").focus();
    }, 100);
  }

  addIdValidation() {
    this.userDetailsFormStepOne.patchValue({
      id: "",
    });
    this.userDetailsFormStepOne.controls["id"].setValidators([
      Validators.compose([Validators.required, this.validateIsraelId]),
    ]);
    this.validation_messages["id"][3]["message"] =
      "יש להזין תעודת זהות תקינה בת 9 ספרות בלבד";
    this.validation_messages["id"][2]["message"] =
      "יש להזין תעודת זהות תקינה בת 9 ספרות בלבד";
    this.userDetailsFormStepOne.controls["id"].updateValueAndValidity();
  }

  deleteIdValidation() {
    this.userDetailsFormStepOne.patchValue({
      id: "",
    });
    this.userDetailsFormStepOne.controls["id"].clearValidators();
    this.userDetailsFormStepOne.controls["id"].setValidators([
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(40),
    ]);
    this.validation_messages["id"][3]["message"] = "יש להזין לפחות 2 תווים";
    this.validation_messages["id"][2]["message"] =
      "יש להזין לכל היותר 40 תווים";
    this.userDetailsFormStepOne.controls["id"].updateValueAndValidity();
  }

  findOptionByValueOrDefault(options: IFieldOptions[], defaultValue?: string) {
    return options.find((option) =>
      defaultValue ? option.sfValue === defaultValue : option.default_value
    );
  }

  setValidatorsFor_eligibility_study_fund(boolean) {
    if (!boolean) {
      this.userDetailsFormStepThree.controls[
        "is_up_to_the_ceiling_of_the_training_fund"
      ].setValidators([Validators.required]);
      this.userDetailsFormStepThree.controls[
        "is_up_to_the_ceiling_of_the_training_fund"
      ].updateValueAndValidity();
      this.userDetailsFormStepThree.patchValue({
        is_up_to_the_ceiling_of_the_training_fund:
          this.findOptionByValueOrDefault(this.study_fund_deposit) ||
          this.study_fund_deposit[0],
      });

      this.userDetailsFormStepThree.controls[
        "employer_compensation"
      ].setValidators([Validators.compose([Validators.required])]);
      this.userDetailsFormStepThree.controls[
        "employer_compensation"
      ].updateValueAndValidity();

      this.userDetailsFormStepThree.controls[
        "employee_compensation"
      ].setValidators([Validators.compose([Validators.required])]);
      this.userDetailsFormStepThree.controls[
        "employee_compensation"
      ].updateValueAndValidity();

      this.userDetailsFormStepThree.controls["date_training_fund"].setValue(
        this.findOptionByValueOrDefault(
          this.eligibility_date_study_fund,
          this.study_fund_date_of_entitlement
        )
      );
      this.userDetailsFormStepThree.controls[
        "date_training_fund"
      ].setValidators([Validators.required]);
      this.userDetailsFormStepThree.controls[
        "date_training_fund"
      ].updateValueAndValidity();

      // set the Default Dropdown value
      let employerObj = this.study_fund_employer_deposit.find((item) => {
        return item.default_value === true;
      });
      if (employerObj)
        this.userDetailsFormStepThree.controls[
          "employer_compensation"
        ].setValue(employerObj);

      let employeeObj = this.study_fund_employee_deposit.find((item) => {
        return item.default_value === true;
      });
      if (employeeObj)
        this.userDetailsFormStepThree.controls[
          "employee_compensation"
        ].setValue(employeeObj);
    } else {
      this.userDetailsFormStepThree.controls[
        "is_up_to_the_ceiling_of_the_training_fund"
      ].clearValidators();
      this.userDetailsFormStepThree.controls[
        "is_up_to_the_ceiling_of_the_training_fund"
      ].updateValueAndValidity();

      this.userDetailsFormStepThree.controls[
        "input_for_other_study_fund_deposit"
      ].clearValidators();
      this.userDetailsFormStepThree.controls[
        "input_for_other_study_fund_deposit"
      ].updateValueAndValidity();
      this.userDetailsFormStepThree.controls[
        "input_for_percentage_study_fund_deposit"
      ].clearValidators();
      this.userDetailsFormStepThree.controls[
        "input_for_percentage_study_fund_deposit"
      ].updateValueAndValidity();
      this.userDetailsFormStepThree.controls[
        "employer_compensation"
      ].clearValidators();
      this.userDetailsFormStepThree.controls[
        "employer_compensation"
      ].updateValueAndValidity();

      this.userDetailsFormStepThree.controls[
        "employee_compensation"
      ].clearValidators();
      this.userDetailsFormStepThree.controls[
        "employee_compensation"
      ].updateValueAndValidity();

      this.userDetailsFormStepThree.controls[
        "date_training_fund"
      ].clearValidators();
      this.userDetailsFormStepThree.controls[
        "date_training_fund"
      ].updateValueAndValidity();

      this.userDetailsFormStepThree.patchValue({
        is_up_to_the_ceiling_of_the_training_fund: "",
        employer_compensation: "",
        employee_compensation: "",
        date_training_fund: "",
        input_for_other_study_fund_deposit: "",
        input_for_percentage_study_fund_deposit: "",
      });

      this.showExtraFormField("", "eligibility_date_training_fund");
    }
  }

  checkValidationStepOne() {
    this.dataService.showGhostElements = true;
    let body = {
      personal_details: {
        first_name: this.profileObject.first_name,
        last_name: this.profileObject.last_name,
        id_number: this.profileObject.id,
        id_number_type: this.profileObject.idRadio ? "תעודת זהות" : "דרכון",
        mobile_phone: this.profileObject.mobile_phone,
        email: this.profileObject.email,
        gender: this.profileObject.gender,
        family_status: this.profileObject.family_status["value"],
        street: this.profileObject.street,
        city: this.profileObject.city,
        bulding_number: this.profileObject.bulding_number,
        date_of_birth: this.profileObject.birthdate,
      },
    };

    this.dataService.showGhostElements = false;
    this.nextStep.emit();
    this.disableContinueButton = false;
    this.insertButtonsName(this.stageNumber + 1);
  }

  public validateIsraelId(control: UntypedFormControl) {
    let invalid = false;

    let id = String(control.value).trim();
    if (id.length !== 9 || isNaN(+id || NaN)) {
      invalid = true;
    }
    if (!invalid) {
      id = id.length < 9 ? ("00000000" + id).slice(-9) : id;
      const calculated =
        Array.from(id, Number).reduce((counter, digit, i) => {
          const step = digit * ((i % 2) + 1);
          return counter + (step > 9 ? step - 9 : step);
        }) %
          10 ===
        0;
      invalid = !calculated;
    }

    if (invalid) {
      return { pattern: true };
    } else {
      return null;
    }
  }

  public isMyDateFormat(control: UntypedFormControl) {
    let date = moment(control.value).format("DD/MM/YYYY");
    let invalid = false;
    const da = date.split("/");
    let today = new Date();

    if (
      da.length !== 3 ||
      da[0].length !== 2 ||
      da[1].length !== 2 ||
      da[2].length !== 4
    ) {
      invalid = true;
    } else if (moment(date).diff(moment(today).format("DD/MM/YYYY")) > 0) {
      invalid = true;
    }

    if (invalid) {
      return { pattern: true };
    } else {
      return null;
    }
  }

  insertButtonsName(number) {
    if (number == 1) {
      this.button_2_name =
        this.textService.textSource["NEW_EMPLOYER_STEP_1_BUTTON_2"];
      this.button_1_name =
        this.textService.textSource["NEW_EMPLOYER_STEP_1_BUTTON_1"];
      setTimeout(() => {
        $(".img").focus();
      });
    }
    if (number == 2) {
      this.button_2_name =
        this.textService.textSource["NEW_EMPLOYER_STEP_2_BUTTON_2"];
      this.button_1_name =
        this.textService.textSource["NEW_EMPLOYER_STEP_2_BUTTON_1"];
      setTimeout(() => {
        $(".img").focus();
      });
    }
    if (number == 3) {
      this.button_2_name =
        this.textService.textSource["NEW_EMPLOYER_STEP_3_BUTTON_2"];
      this.button_1_name =
        this.textService.textSource["NEW_EMPLOYER_STEP_3_BUTTON_1"];

      setTimeout(() => {
        $(".img").focus();
      });
    }
  }

  /**
   * Displays additional form fields depending on the values of the 'status' and 'controlName' parameters.
   * If the value of 'status.label' is equal to 'אחר', an additional form field will be displayed, along with its corresponding control validators.
   * If the value of 'status.label' is anything other than 'אחר', no additional form fields will be displayed.
   * @param {object} status - An object containing the label of the status.
   * @param {string} controlName - The name of the form control to which the additional fields should be added.
   */
  showExtraFormField(status, controlName: string, extraField = "אחר") {
    if (status["label"] === extraField) {
      this.displayExtraFormField(controlName, true);
      const validators = this.getExtraFormFieldValidators(controlName);

      this.userDetailsFormStepThree.controls[controlName].setValidators(
        validators
      );
      this.userDetailsFormStepThree.controls[
        controlName
      ].updateValueAndValidity();
    } else {
      this.displayExtraFormField(controlName, false);
      const defaultValue = this.getExtraFormFieldDefaultValue(controlName);

      this.userDetailsFormStepThree.controls[controlName].clearValidators();
      this.userDetailsFormStepThree.controls[
        controlName
      ].updateValueAndValidity();

      this.userDetailsFormStepThree.patchValue({
        [controlName]: defaultValue,
      });
    }
  }

  showMore(status) {
    this.showExtraFormField(status, "input_for_other_study_fund_deposit");
    this.showExtraFormField(
      status,
      "input_for_percentage_study_fund_deposit",
      "% משכר"
    );
    this.showExtraFormField(status, "input_for_other_study_fund_deposit");
    this.showExtraFormField(
      status,
      "input_for_percentage_study_fund_deposit",
      "% משכר"
    );
  }

  getExtraFormFieldValidators(controlName: string) {
    return (
      {
        ["allowance_compensation_other"]: [
          Validators.max(10),
          Validators.min(0),
          Validators.required,
          Validators.pattern(this.NumbersOrDecimalsRegex),
        ],
        ["pension_arrangement_chosen_other"]: [Validators.required],
        ["eligibility_date_training_fund"]: [Validators.required],
        ["input_for_other_study_fund_deposit"]: [
          Validators.required,
          Validators.maxLength(6),
          Validators.pattern(/^[0-9]+$/i),
        ],
        ["input_for_percentage_study_fund_deposit"]: [
          Validators.required,
          Validators.maxLength(6),
          Validators.pattern(/^[0-9]+$/i),
        ],
        ["date_pension_arrangement"]: [Validators.required],
      }[controlName] ?? []
    );
  }

  getExtraFormFieldDefaultValue(controlName: string) {
    return (
      {
        ["allowance_compensation_other"]: null,
        ["pension_arrangement_chosen_other"]: null,
        ["eligibility_date_training_fund"]: "",
        ["input_for_other_study_fund_deposit"]: "",
        ["input_for_percentage_study_fund_deposit"]: "",
      }[controlName] ?? ""
    );
  }

  displayExtraFormField(controlName: string, isVisible: boolean) {
    switch (controlName) {
      case "allowance_compensation_other":
        this.manual_allowance_compensation_field = isVisible;
        break;
      case "date_pension_arrangement":
        this.pension_arrangement_chosen_other = isVisible;
        break;
      case "eligibility_date_training_fund":
        this.date_training_fund_chosen_other = isVisible;
        break;
      case "input_for_other_study_fund_deposit":
        this.showInputDeposit = isVisible;
        break;
      case "input_for_percentage_study_fund_deposit":
        this.showInputPercentageDeposit = isVisible;
        break;
      default:
        break;
    }
  }

  updateEmployeeDataFields(data: IEmployeeData) {
    if (
      this.profileObject.moed_zakaut_default_value["label"] === "כחוק" &&
      this.userDetailsFormStepThree.value.pension_arrangement["label"] ===
        "כחוק"
    ) {
      data.social_allowances.eligibility_date = null;
    }

    if (
      this.userDetailsFormStepThree.value
        .is_up_to_the_ceiling_of_the_training_fund["label"] === "אחר"
    ) {
      data.fund_study.other_study_fund_deposit =
        this.userDetailsFormStepThree.value.input_for_other_study_fund_deposit;
    }

    if (
      this.userDetailsFormStepThree.value
        .is_up_to_the_ceiling_of_the_training_fund["label"] === "% משכר"
    ) {
      data.fund_study.percentage_study_fund_deposit =
        this.userDetailsFormStepThree.value.input_for_percentage_study_fund_deposit;
    }

    if (
      this.userDetailsFormStepThree.value.allowance_compensation["label"] ===
      "אחר"
    ) {
      data.social_allowances.allowance_compensations_percentage =
        this.userDetailsFormStepThree.value.allowance_compensation_other;
      this.userDetailsFormStepThree
        .get("date_pension_arrangement")
        .setValidators([Validators.required]);
      this.userDetailsFormStepThree
        .get("date_pension_arrangement")
        .setValidators([Validators.required]);
    }
  }

  onChanges() {
    this.userDetailsFormStepThree.controls.eligibility_study_fund.valueChanges.subscribe(
      (value) => this.setValidatorsFor_eligibility_study_fund(!value)
    );
  }

  changeForeignEmployeeValue(el) {
    el.value ? this.chooseIdRadio() : this.choosePassport();
    this.userDetailsFormStepOne.patchValue({
      foreign_employee: !el.value,
    });
  }

  setFieldDefaultValue(
    form: UntypedFormGroup,
    controlName: string,
    field: IFieldOptions[]
  ) {
    form.patchValue({
      [controlName]: this.findOptionByValueOrDefault(field) || field[0],
    });
  }
  handleSalaryMethodChange(showDialog: any) {
    this.showDialog = false;
  }
  salaryOptionValidator() {
    const employeeSalary =
      this.userDetailsFormStepTwo.get("employeeSalary")?.value;
    const selectedOption =
      this.userDetailsFormStepTwo.get("salary_method")?.value.value;

    // בדיקה אם השכר גדול מ-1000 והאפשרות שנבחרה היא "שעתי"
    if (Number(employeeSalary) > 1000 && selectedOption === "hourly") {
      this.showDialog = true;
      debugger;
      // setTimeout(() => {
      //   this.showDialog = false;
      // }, 2000);
    } else this.showDialog = false;
  }
}
